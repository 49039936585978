// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_InputLabel__2PWQt{
    font-size: 1.4rem;
    display: block;
    margin-right:1rem;
}`, "",{"version":3,"sources":["webpack://./src/component/element/form/input.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".InputLabel{\n    font-size: 1.4rem;\n    display: block;\n    margin-right:1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputLabel": `input_InputLabel__2PWQt`
};
export default ___CSS_LOADER_EXPORT___;
