// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideNav {
  opacity: 1;
  background-color: white;
  width: 670px;
  height: 100vh;
  position: absolute;
  z-index: 40;
  padding-left: 2.5rem /* 40px */;
  padding-right: 2.5rem /* 40px */;
  padding-top: 1.25rem /* 20px */;
  padding-bottom: 1.25rem /* 20px */;
}

.toplinks {
  padding-bottom: 2.5rem /* 40px */;
  padding-top: 0.5rem /* 8px */;
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
  --tw-border-opacity: 1;
  border-color: rgb(130 130 130 / var(--tw-border-opacity));
  margin-bottom: 2rem /* 32px */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 400;
  font-size: 14px;
  color: black;
}

a > span {
  color: black;
}

a > span:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 700px) {
  .sideNav {
    width: 100%;
    padding-left: 1rem /* 40px */;
    padding-right: 1rem /* 40px */;
    padding-top: 0.5rem /* 20px */;
    padding-bottom: 0.5rem /* 20px */;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/sideNav.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,+BAA+B;EAC/B,gCAAgC;EAChC,+BAA+B;EAC/B,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;EACjC,6BAA6B;EAC7B,+BAA+B;EAC/B,gCAAgC;EAChC,sBAAsB;EACtB,yDAAyD;EACzD,8BAA8B;EAC9B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;IACX,6BAA6B;IAC7B,8BAA8B;IAC9B,8BAA8B;IAC9B,iCAAiC;EACnC;AACF","sourcesContent":[".sideNav {\n  opacity: 1;\n  background-color: white;\n  width: 670px;\n  height: 100vh;\n  position: absolute;\n  z-index: 40;\n  padding-left: 2.5rem /* 40px */;\n  padding-right: 2.5rem /* 40px */;\n  padding-top: 1.25rem /* 20px */;\n  padding-bottom: 1.25rem /* 20px */;\n}\n\n.toplinks {\n  padding-bottom: 2.5rem /* 40px */;\n  padding-top: 0.5rem /* 8px */;\n  padding-left: 0.25rem /* 4px */;\n  padding-right: 0.25rem /* 4px */;\n  --tw-border-opacity: 1;\n  border-color: rgb(130 130 130 / var(--tw-border-opacity));\n  margin-bottom: 2rem /* 32px */;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid;\n}\n\n.links {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-weight: 400;\n  font-size: 14px;\n  color: black;\n}\n\na > span {\n  color: black;\n}\n\na > span:hover {\n  text-decoration: underline;\n  text-underline-offset: 10px;\n  font-weight: 600;\n  font-size: 16px;\n}\n\n@media (max-width: 700px) {\n  .sideNav {\n    width: 100%;\n    padding-left: 1rem /* 40px */;\n    padding-right: 1rem /* 40px */;\n    padding-top: 0.5rem /* 20px */;\n    padding-bottom: 0.5rem /* 20px */;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
