// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePageLanding_Wrapper__VTyoi{
    background-color: #ffff;
    /* max-width: 129.6rem; 
    margin: auto; */
}

.HomePageLanding_section_Banner__YkrVa{
    width: 100%;
    background-color: rgba(224, 224, 224, 1);

}

.HomePageLanding_info__GmDm4 {
    margin: auto;
    margin-top: 40rem !important;
  }
  
  .HomePageLanding_info_wrapper__N0aba {
    color: rgba(51, 51, 51, 1);
  }
  
  .HomePageLanding_info_wrapper__N0aba p:last-child {
    margin-bottom: 0rem;
  }



@media screen and (min-width: 1450px) {
    .HomePageLanding_Wrapper__VTyoi,.HomePageLanding_info__GmDm4 {
      width: 1450px;
      margin: auto;
    }
}


@media screen and (max-width: 450px) {
  .HomePageLanding_info__GmDm4 {
    margin-top: 10rem !important;
  }
}

`, "",{"version":3,"sources":["webpack://./src/pages/customer/homePageLanding/HomePageLanding.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;mBACe;AACnB;;AAEA;IACI,WAAW;IACX,wCAAwC;;AAE5C;;AAEA;IACI,YAAY;IACZ,4BAA4B;EAC9B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;EACrB;;;;AAIF;IACI;MACE,aAAa;MACb,YAAY;IACd;AACJ;;;AAGA;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".Wrapper{\n    background-color: #ffff;\n    /* max-width: 129.6rem; \n    margin: auto; */\n}\n\n.section_Banner{\n    width: 100%;\n    background-color: rgba(224, 224, 224, 1);\n\n}\n\n.info {\n    margin: auto;\n    margin-top: 40rem !important;\n  }\n  \n  .info_wrapper {\n    color: rgba(51, 51, 51, 1);\n  }\n  \n  .info_wrapper p:last-child {\n    margin-bottom: 0rem;\n  }\n\n\n\n@media screen and (min-width: 1450px) {\n    .Wrapper,.info {\n      width: 1450px;\n      margin: auto;\n    }\n}\n\n\n@media screen and (max-width: 450px) {\n  .info {\n    margin-top: 10rem !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": `HomePageLanding_Wrapper__VTyoi`,
	"section_Banner": `HomePageLanding_section_Banner__YkrVa`,
	"info": `HomePageLanding_info__GmDm4`,
	"info_wrapper": `HomePageLanding_info_wrapper__N0aba`
};
export default ___CSS_LOADER_EXPORT___;
